import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, unref } from 'vue';
import { usePositionHistory } from '@/composables/usePositionHistory.ts';
import { useStopHistory } from '@/composables/useStopHistory.ts';
import { usePosition } from '@/composables/usePosition.ts';
import { useDevice } from '@/composables/useDevice.ts';
import { useFilter } from '@/composables/useFilter.ts';
import { useGroup } from '@/composables/useGroup.ts';
import { getFilterResultsByLink } from '@/utils/device-filter.ts';
import isEmpty from 'lodash/isEmpty';
import { useRoute } from 'vue-router';

type TViewType = 'deviceView' | 'groupList' | 'groupDetail';

export const useMap = defineStore('map', () => {
  const viewType = ref<TViewType>('deviceView');
  const isAutoRefreshing = ref(false);

  const route = useRoute();
  const positionHistoryStore = usePositionHistory();
  const stopHistory = useStopHistory();
  const positionStore = usePosition();
  const deviceStore = useDevice();
  const groupStore = useGroup();
  const filterStore = useFilter();

  const { isLoading: isLoadingDevices, positions } = storeToRefs(positionStore);
  const {
    selectedIds,
    filter,
    devices,
    resultDeviceIds,
    isAtLeastOneFilterApplied,
  } = storeToRefs(deviceStore);

  const { dateFilter } = storeToRefs(filterStore);

  const { resultDeviceIds: resultGroupDeviceIds, isLoading: isLoadingGroups } =
    storeToRefs(groupStore);

  const {
    getHistoryPositionsByDateForDeviceId,
    getHistoryPositionsByDateForAllDevices,
  } = positionHistoryStore;
  const { getStopHistoryByDateForDeviceId } = stopHistory;

  const isLoading = computed(
    () => isLoadingGroups.value || isLoadingDevices.value,
  );

  const computedDevices = computed(() => {
    if (viewType.value === 'deviceView') {
      if (selectedIds.value.length) {
        return selectedIds.value;
      }

      return resultDeviceIds.value;
    }

    let resultIds = [];
    if (viewType.value === 'groupList') {
      resultIds = resultGroupDeviceIds.value;
    }

    if (viewType.value === 'groupDetail') {
      if (selectedIds.value.length) {
        resultIds = selectedIds.value;
      } else {
        resultIds = resultGroupDeviceIds.value;
      }
    }

    if (isAtLeastOneFilterApplied.value) {
      resultIds = getFilterResultsByLink(
        resultIds,
        devices.value,
        filter.value,
      );
    }
    return resultIds;
  });

  const computedPositions = computed(() => {
    const result = [];
    computedDevices.value.forEach((deviceId) => {
      const position = positions.value.get(deviceId);
      if (position) {
        result.push(position);
      }
    });
    return result;
  });

  const clusterPositionsHistory = computed(() => {
    let positions = getHistoryPositionsByDateForAllDevices();
    if (viewType.value === 'deviceView') {
      if (resultDeviceIds.value.length > 0) {
        positions = positions.filter((position) =>
          resultDeviceIds.value.includes(position.id),
        );
      }
      return !selectedIds.value.length
        ? positions
        : positions.filter((position) =>
            selectedIds.value.includes(position.id),
          );
    }
    if (viewType.value === 'groupList') {
      return positions.filter((item) =>
        resultGroupDeviceIds.value.includes(item.id),
      );
    }
    if (viewType.value === 'groupDetail') {
      const positions = getHistoryPositionsByDateForAllDevices().filter(
        (item) => resultGroupDeviceIds.value.includes(item.id),
      );
      return isEmpty(selectedIds.value)
        ? positions
        : positions.filter((item) => selectedIds.value.includes(item.id));
    }
    return [];
  });

  const singlePositionsHistory = computed(() =>
    getHistoryPositionsByDateForDeviceId(
      +route.params.id,
      unref(dateFilter).dateFrom,
      unref(dateFilter).dateTo,
    ),
  );
  const stopsHistory = computed(() =>
    getStopHistoryByDateForDeviceId(
      +route.params.id,
      unref(dateFilter).dateFrom,
      unref(dateFilter).dateTo,
    ),
  );

  return {
    viewType,
    isLoading,
    computedPositions,
    computedDevices,
    singlePositionsHistory,
    clusterPositionsHistory,
    stopsHistory,
    isAutoRefreshing,
    setViewType: (_viewType: TViewType) => (viewType.value = _viewType),
  };
});
